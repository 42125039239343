'use client'
import { createContext, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'next/navigation'
import { i18n } from '@/i18n-config'

const getPath = (value) => {
  if (!value) return value;
  return value.startsWith('/') ? value : `/${value}`;
}

export const LocalesContext = createContext()

export const LocalesProvider = ({ children }) => {
  const params = useParams()
  const locales = useMemo(() => i18n.locales, [])
  const [availableLocales, setAvailableLocales] = useState([])

  const updateAvailableLocales = (data) => {
    const _activeLocales = locales.filter(i => i.hrefLang !== params.locale).reduce((acc, item) => {
      let itemLocal = {}
      if (Array.isArray(data)) {
        itemLocal = data.find(i => item.localeCode === i.localeCode)
      }
      acc.push({
        slug: itemLocal?.slug ? getPath(itemLocal.slug) : '/',
        name: item.name,
        hrefLang: item.hrefLang
      })
      return acc
    }, [])
    setAvailableLocales(_activeLocales)
  }

  return (
    <LocalesContext.Provider value={{ availableLocales, updateAvailableLocales }}>
      {children}
    </LocalesContext.Provider>
  )
}

LocalesProvider.propTypes = {
  children: PropTypes.node
}
