"use client"
import { useMemo } from 'react'
import NextLink from 'next-intl/link'
import { usePathname } from 'next-intl/client'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import usePrefetchOnHover from '@/app/hooks/usePrefetchOnHover'

import { handleScrollToElement } from '@/app/utils/handleScrollToElement'

const socialNetwork = ['tel', 'mailto', 'skype', 'tg', 'whatsapp', 'viber']

export default function Link({ prefetch, href, children, className, isOpenNewTab, ...props }) {
  const pathname = usePathname()
  const prefetchOnHover = usePrefetchOnHover(prefetch || 'onHover')

  const localHref = useMemo(() => {
    if (/^(http|https)/.test(href)) {
      return href
    }
    return href.startsWith('/') ? href : `/${href}`
  }, [href])

  const isTargetBlank = useMemo(() => {
    if (isOpenNewTab === false) {
      return false
    }
    if (isOpenNewTab) {
      return true
    }
    // default behavior
    return /^(http|https)/.test(localHref)
  }, [localHref, isOpenNewTab])

  const isNofollow = useMemo(() => {
    return /^(http|https)/.test(localHref)
  }, [localHref])

  const isCmsMedia = useMemo(() => {
    return /^(\/cms\/image\/uploads)/.test(localHref)
  }, [localHref])

  if (!href) {
    return null
  }

  if (socialNetwork.some((part) => href.startsWith(part))) {
    return (
      <a
        className={className}
        href={href}
        target={'_blank'}
        {...props}
      >
        {children}
      </a>
    )
  }

  if (/^(#)/.test(href)) {
    return (
      <NextLink
        href={href}
        passHref={true}
        legacyBehavior={true}
      >
        <a
          className={className}
          onClick={(e) => {
            e.preventDefault()
            const section = document.querySelector(href)
            handleScrollToElement(section)
          }}
          {...props}
        >
          {children}
        </a>
      </NextLink>
    )
  }

  if (isCmsMedia) {
    return (
      <a
        href={localHref}
        className={className}
        target={isTargetBlank ? '_blank' : null}
        rel={isNofollow ? 'nofollow' : null}
        {...props}
      >
        {children}
      </a>
    )
  }

  return (
    <NextLink
      href={localHref}
      className={classNames(
        {
          'b-active': pathname === href
        },
        className
      )}
      target={isTargetBlank ? '_blank' : null}
      rel={isNofollow ? 'nofollow' : null}
      {...prefetchOnHover}
      {...props}
    >
      {children}
    </NextLink>
  )
}

Link.propTypes = {
  prefetch: PropTypes.string,
  href: PropTypes.string.isRequired,
  className: PropTypes.string,
  isOpenNewTab: PropTypes.any,
  children: PropTypes.any
}