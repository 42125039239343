import { useState } from 'react'

export default function usePrefetchOnHover(prefetch) {
  const [shouldPrefetch, setShouldPrefetch] = useState(false)
  if (prefetch === 'onHover') {
    return {
      prefetch: shouldPrefetch,
      onMouseOver: () => setShouldPrefetch(undefined),
      onFocus: () => setShouldPrefetch(undefined),
    }
  }
  return { prefetch: prefetch ? undefined : prefetch }
}