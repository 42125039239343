export const i18n = {
  defaultLocale: 'ua',
  locales: [
    {
      id: 'ua',
      name: 'Ua',
      fullName: 'Українська',
      localeCode: 'uk',
      hrefLang: 'ua',
      isDefault: true
    },
    {
      id: 'ru',
      name: 'Ru',
      fullName: 'Русский',
      localeCode: 'ru',
      hrefLang: 'ru',
      isDefault: false
    },
    {
      id: 'en',
      name: 'En',
      fullName: 'English',
      localeCode: 'en',
      hrefLang: 'en',
      isDefault: false
    }
  ]
}
