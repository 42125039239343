export const handleScrollToElement = (el, offset = 0) => {
  if (el && window) {
    const headerMenu = document.getElementById('header-menu')
    const headerMenuHeight = headerMenu ? headerMenu.clientHeight : 0
    const offsetTop =
      window.innerWidth <= 1024
      ? el.offsetTop - 80 - offset
      : el.offsetTop - (offset || headerMenuHeight)
    setTimeout(() => {
      window.scrollTo({ top: offsetTop })
    }, 200)
  }
}