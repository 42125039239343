'use client'
import { useEffect } from 'react'
import { handleScrollToElement } from '@/app/utils/handleScrollToElement'
import Script from 'next/script'
import useLocales from '@/app/hooks/useLocales'

let resizeBody

export default function PageLayout({
  children,
  isWhiteHeader,
  headScript,
  bodyScript,
  localizations
}) {
  const { updateAvailableLocales } = useLocales()

  useEffect(() => {
    updateAvailableLocales(localizations)
  }, [localizations])

  useEffect(() => {
    // test
    if (isWhiteHeader) {
      document.body.classList.add('b-white-header')
    }
    return () => {
      document.body.classList.remove('b-white-header')
    }
  }, [isWhiteHeader])

  // scroll to section after refresh page
  const scrollToSection = () => {
    const id = window.location.hash.split('?')?.[0]
    if (id) {
      const section = document.querySelector(id)
      handleScrollToElement(section)
    }
  }

  const removeObserve = () => {
    resizeBody.unobserve(document.body)
    window.removeEventListener('click', removeObserve)
    window.removeEventListener('keypress', removeObserve)
    window.removeEventListener('wheel', removeObserve)
    window.removeEventListener('touchstart', removeObserve)
  }

  useEffect(() => {
    resizeBody = new ResizeObserver(scrollToSection)
    resizeBody.observe(document.body)
    window.addEventListener('click', removeObserve)
    window.addEventListener('keypress', removeObserve)
    window.addEventListener('wheel', removeObserve)
    window.addEventListener('touchstart', removeObserve)
    return () => {
      removeObserve()
    }
  }, [])

  return (
    <>
      {headScript && (
        <Script
          id='pageHeadScript'
          strategy="beforeInteractive"
          dangerouslySetInnerHTML={{
            __html: headScript
          }}
        />
      )}
      {children}
      {bodyScript && (
        <Script
          id='pageBodyScript'
          dangerouslySetInnerHTML={{
            __html: bodyScript
          }}
        />
      )}
    </>
  )
}